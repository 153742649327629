@import "abstracts/_abstracts";
.HomeContactForm {
    padding: 4rem 0;
    margin-bottom: calc(var(--spaceXl) * -1);

    &-frame {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem 4rem;
    }

    &-title {
        font-weight: 800;
        font-size: clamp(1.5rem, 3vw, 2rem);
        line-height: 1.25;
        color: var(--colorText);

        span {
            display: block;
            color: var(--colorBrand);
        }
    }

    &-text {
        width: 25rem;

        @include media('<=md') {
            width: 100%;
        }
    }

    &-perex {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
    }

    &-form {
        flex: 1;
    }

    button {

    }
}

.AppForm {
    &-title {
        display: none;
    }
}